import * as types from './types';

export const updateProducts = (products) => (dispatch) => (
  dispatch({
    type: types.PRODUCTS_UPDATE_PRODUCTS,
    products
  })
);

export const updateSlug = (slug) => (dispatch) => (
  dispatch({
    type: types.PRODUCTS_UPDATE_SLUG,
    slug
  })
);

export const updateProduct = (id) => (dispatch) => (
  dispatch({
    type: types.PRODUCTS_UPDATE_CLICKED_PRODUCT,
    id
  })
);
