/*
this is used for:
- Algolia Taxon Products CMS Module
- PDP Recommendation Product Carousels
- Search results dropdown
*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { InView } from 'react-intersection-observer';
import router from 'next/router';
import { toast, TOAST } from '../../utils/toastify';
import Link from '../../utils/link';
import getPromoMessages from '../../utils/getPromoMessages';

import FastlyPicture from '../../atoms/fastly-picture';
import Button from '../../atoms/button';
import IconButton from '../../molecules/icon-button';
import IconHeart from '../../atoms/icon-heart';
import Price, { PriceSale } from '../../organisms/algolia-plp/product-card-price';
import Typography from '../../atoms/typography';

import parseSlugFromProductUrl from '../../utils/parseSlugFromProductUrl';

import { createWishedProduct, deleteWishedProduct } from '../../pages/api';
import {
  addProductToList,
  addProductToAlgoliaList,
  removeProductFromAlgoliaList,
  removeProductFromList
} from '../../store/modules/lists/actions';
import { updateProduct } from '../../store/modules/products/actions';
import trackEvent, { trackProductClick } from '../../utils/tracking';
import { logAmplitude } from '../../utils/amplitude';
import handleOnImageError from '../../utils/handleOnImageError';

const FASTLY_ON = process.env.NEXT_PUBLIC_FASTLY_ON === 'true';

const Heart = styled(IconHeart)`
  fill: ${(props) => props.theme.color.white};
  stroke: ${(props) => props.theme.color.brand};

  &.active {
    fill: ${(props) => props.theme.color.brand};
  }
`;

const Wishlist = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})`
  -webkit-appearance: none;
  background: ${({ theme, active }) => active ? theme.color.white : 'rgba(255, 255, 255, 0.65)'};
  border-radius: 100%;
  border: 0 none;
  cursor: pointer;
  height: 4rem;
  line-height: 1;
  margin: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 4rem;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);

  svg {
    transform: scale(0.75);
    margin-right: 0;
    height: 4rem;
    width: 4rem;
  }

  :hover {
    transform: scale(1.15);
  }
`

const QuickShopButton = styled(Button)`
  bottom: .6rem;
  display: none;
  height: calc(3rem);
  left: 50%;
  line-height: 1;
  opacity: 0;
  outline: 0;
  position: absolute;
  transform: translateX(-50%);
  width: fit-content;
  transition: opacity ${(props) => props.theme.animation.slow} ${(props) => props.theme.animation.easeOutQuad},
    transform ${(props) => props.theme.animation.default} ${(props) => props.theme.animation.easeOutQuad};

  :focus {
    opacity: 1;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    display: block;
    max-width: 16rem;
    width: 100%;
  }
`;

const QuickshopButtonWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  left: 0;
  max-width: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
`;

const FILTERED_IMGWRAPPER_EMO_PROPS = new Set(['FASTLY_ON', 'showQuickShop']);

const ImageWrapper = styled('figure', {
  shouldForwardProp: (prop) => !FILTERED_IMGWRAPPER_EMO_PROPS.has(prop)
})`
  margin-bottom: ${({ showQuickShop }) => (showQuickShop ? '3rem' : 0)};
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  text-align: center;

  ${FASTLY_ON ? 'picture' : Image} + ${FASTLY_ON ? 'picture' : Image} {
    opacity: 0;
    pointer-events: none;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    margin-bottom: ${({ showQuickShop }) => (showQuickShop ? '4rem' : 0)};
  }
`;

const CardAnchor = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    ${(props) => (
      props.hasSideImage
        ? css`
          ${FASTLY_ON ? 'picture' : Image} {
            transition: opacity ${props.theme.animation.slow} ${props.theme.animation.easeOutQuad};
          }
          :hover {
            ${FASTLY_ON ? 'picture' : Image} {
              opacity: 0;

              + ${FASTLY_ON ? 'picture' : Image} {
                opacity: 1;
              }
            }
          }
        ` : ''
    )}
  }

  :hover, :focus {
    ${QuickShopButton}, ${Wishlist} {
      opacity: 1;
    }
  }
`;

const Card = styled.article`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  &.--pulse {
    ${CardAnchor} {
      animation: ${({ theme }) => theme.animations.pulse} ${({ theme }) => theme.animation.default};
    }
  }
`;

const ProductTitleWrapper = styled.div`
  margin-top: 1.6rem;
  color: ${(props) => props.theme.color.brand};
  display: flex;
  flex-direction: column;
  line-height: 2.2rem;
  text-align: left;
`;

const BrandText = styled(Typography)`
  color: ${({ theme }) => theme.color.bluePrimary};
  order: 1;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.modularScale.thirteen};
  letter-spacing: 1px;
`;

const ProductTitle = styled(Typography)`
  order: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${({ theme }) => theme.modularScale.thirteen};
`;

const PriceText = styled(Price)`
  order: 3;
  text-align: left;
  justify-content: left !important;
  font-size: ${({ theme }) => theme.modularScale.fourteen};
  letter-spacing: 1px;

  > ${PriceSale} {
    justify-content: flex-start;
  }
`;

const Badge = styled(Typography)`
  order: 5;
  margin-top: 1.6rem;
`;

const IntersectionObserverElement = styled.span`
  visibility: hidden;
`;

const CardPromo = styled(Typography)`
  order: 4;
  width: 100%;
  color: ${({ theme }) => theme.color.redError};
  line-height: 1.7rem;
  margin-top: 0.4rem;
`;

const OutletBadgeWrapper = styled.div`
  margin-top:1.6rem;
  order:5;
  display:flex;
`;

const OutletBadgeText = styled.span`
  color: ${({ theme }) => theme.color.redError};
  border: 1px solid ${({ theme }) => theme.color.redError};
  padding: 4px 8px;
  font-weight: 400;
  line-height: 14px;
`;
const CardText = ({ product , isSearch}) => (
  <ProductTitleWrapper>
    <BrandText element="p" like="paragraph-7">
      {product.brand}
    </BrandText>
    <ProductTitle element="p" like="paragraph-7">
      {product.title}
    </ProductTitle>
    <PriceText hit={product} />
    {product?.active_advertised_promotions && product?.active_advertised_promotions.length > 0
      ? getPromoMessages(product.active_advertised_promotions).map((activePromoObject) => (
          <CardPromo element="p" like="dec-5">
            {activePromoObject.plp_promotional_text}
          </CardPromo>
        ))
      : null}
    { (product.outlet && isSearch) ? (
      <OutletBadgeWrapper>
                  <OutletBadgeText>Outlet</OutletBadgeText>
                </OutletBadgeWrapper>
    ):
    product.trends?.length > 0 && (
      <Badge element="p" like="paragraph-4">
        {product.trends[0]}
      </Badge>
    )}
  </ProductTitleWrapper>
);

CardText.defaultProps = {
  product: {},
  isSearch:false
};

CardText.propTypes = {
  product: PropTypes.object,
  isSearch: PropTypes.bool
};

export const ProductCard = (props) => {
  const [wishlistActive, setWishlistActive] = useState(() => {
    if (props?.product?.product_id_wishlist) {
      return props.lists.wished_products
        .find((p) => p.variant_id === +props.product.product_id_wishlist);
    }
    return props.lists.wished_products
      .find((p) => p.variant_id === +props.product.objectID);
  });

  const [isWishlistDisabled, setIsWishlistDisabled] = useState(false);

  useEffect(() => {
    setWishlistActive(() => {
      if (props?.product?.product_id_wishlist) {
        return props.lists.wished_products
          .find((p) => p.variant_id === +props.product.product_id_wishlist);
      }
      return props.lists.wished_products
        .find((p) => p.variant_id === +props.product.objectID);
    });
  }, [props, wishlistActive]);

  const handleOnWishlistClick = (e) => {
    e.preventDefault();

    setIsWishlistDisabled(true);

    let isWishedProduct;
    if (props?.product?.product_id_wishlist) {
      isWishedProduct = props.lists.wished_products
        .find((p) => p.variant_id === +props.product.product_id_wishlist);
    } else {
      isWishedProduct = props.lists.wished_products
        .find((p) => p.variant_id === +props.product.objectID);
    }

    if (isWishedProduct) {
      let id;
      if (props?.product?.product_id_wishlist) {
        const wishedProducts = props.lists.wished_products
          .find((p) => p.variant_id === +props.product.product_id_wishlist);
        id = wishedProducts.id;
      } else {
        const wishedProducts = props.lists.wished_products
          .find((p) => p.variant_id === +props.product.objectID);
        id = wishedProducts.id;
      }

      deleteWishedProduct({ id })
      .then(() => {
        props.removeProductFromList(id);
        props.removeProductFromAlgoliaList(props.product.objectID);
        setIsWishlistDisabled(false);
        // track when a product is removed from wishlist using amplitude
        logAmplitude('Removed from Wishlist', {
          productId: props.product?.objectID,
          productSku: props.product?.manufacturer_id,
          name: props.product?.title,
          badges: props.product?.trends,
          category: props.product?.product_type,
          brand: props.product?.brand
        });
      });
  } else {
      createWishedProduct({
        body: {
          wished_product: {
            variant_id: parseInt(props?.product?.product_id_wishlist ?? props.product.objectID, 10)
          }
        }
      })
        .then((res) => {
          setIsWishlistDisabled(false);

          if (res.errors) {
            const hasAuthError = res?.errors?.find(({ code }) => code === 401);

            if (!props.token || hasAuthError) {
              toast(
                <p>
                  You must be
                  <a href="/login">signed in</a>
                  {' '}
                  to add a product to your wishlist
                </p>,
                { type: TOAST.TYPE.INFO }
              );
            } else if (res.errors.find(({ message }) => message?.includes('has already been taken'))) {
              toast('This product has already been added to your wishlist, please refresh the page for updates', { type: TOAST.TYPE.ERROR });
            } else {
              toast('Something went wrong, please try again later', { type: TOAST.TYPE.ERROR });
            }

            return;
          }

          const algoliaContent = {
            productId: props.product?.objectID,
            productSku: props.product?.manufacturer_id,
            name: props.product?.title,
            badges: props.product?.trends,
            category: props.product?.product_type,
            brand: props.product?.brand
          };

          props.addProductToList(res);
          props.addProductToAlgoliaList({ ...algoliaContent });
          // track when a product is added to wishlist using amplitude
          logAmplitude('Add to Wishlist', { ...algoliaContent });
        });
    }
  };

  const handleOnQuickshopClick = () => {
    props.onQuickshopClick(props.product.maisonette_product_id);
    logAmplitude('Clicked Product Quick View', props);
  };

  const handleOnCardClick = (e) => {
    if (props.trackFor) {
      trackEvent({
        eventCategory: `${props.module === 'taxon_products' ? 'Algolia Taxon Products CMS Module' : 'PDP Recommendations'} - ${props.trackFor}`,
        eventAction: 'Product Clicked',
        eventLabel: parseSlugFromProductUrl(props.product)
      });
    }

    const isQuickShop = e.target.dataset.id === 'quickshop';
    const isWishlist = (e.target.dataset.id === 'wishlist' || e.target.parentElement.dataset.id === 'wishlist');
    
    if (isQuickShop || isWishlist) {
      e.preventDefault();
      return;
    }

    const getListType = () => {
      switch (props.module) {
        case 'also-bought-with':
          return 'Also Bought With';

        case 'related-products':
          return 'Related Products';

        case 'recently-viewed':
          return 'Recently Viewed';

        case 'popular-products':
          return 'No Results - Popular Products';

        default:
          return props.module;
      }
    };

    trackProductClick({
      product: props.product,
      position: props.index,
      list: getListType()
    });

    logAmplitude('Clicked Product Card', props);

    // TODO: Investigate what this Redux state dependency is?
    if (props.product?.slug) {
      props.updateProduct(props.product.slug);
    }

    props.onClick(e);
    router.push(parseSlugFromProductUrl(props.product));
  };

  return (
    <Card
      className={props.className}
      data-test-id="quick-shop-card"
      data-slug={props.product?.slug}
    >
      <>
        {props.intersectionCallback && (
          <InView
            threshold={0}
            onChange={
              (inView) => inView && props.intersectionCallback()
            }
          >
            {({ ref }) => (
              <IntersectionObserverElement
                id={`product-card-intersection-observer-${props.intersectionPosition}`}
                ref={ref}
              />
            )}
          </InView>
        )}
        <Link href={parseSlugFromProductUrl(props.product)} passHref>
          <CardAnchor
            hasSideImage={props.product.side_image}
            onClick={handleOnCardClick}
          >
            <QuickshopButtonWrapper>
              {
                FASTLY_ON ? (
                  <ImageWrapper showQuickShop={props.showQuickShop}>
                    <FastlyPicture
                      type="taxon-product"
                      Image={Image}
                      className={props.disableLazyload ? '' : 'lazyload'}
                      src={
                        props.disableLazyload
                          ? (props.product.image || `${process.env.NEXT_PUBLIC_ASSET_HOST}/images/default-image.jpg`)
                          : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
                      }
                      data-src={props.product.image || `${process.env.NEXT_PUBLIC_ASSET_HOST}/images/default-image.jpg`}
                      alt={props.product.title}
                      onError={handleOnImageError}
                      data-id="image"
                      showDefaultOnError
                    />

                    {
                      props.product.side_image && (
                        <FastlyPicture
                          type={props.taxonProducts ? "taxon-product" : null}
                          Image={Image}
                          className={props.disableLazyload ? '' : 'lazyload'}
                          src={
                            props.disableLazyload
                              ? props.product.side_image
                              : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
                          }
                          data-src={props.product.side_image}
                          alt={props.product.title}
                          onError={handleOnImageError}
                          data-id="image"
                          onLoad={(e) => e.target.classList.remove('loaded')}
                          showDefaultOnError
                        />
                      )
                    }
                  </ImageWrapper>
                ) : (
                    <ImageWrapper showQuickShop={props.showQuickShop}>
                      <Image
                        className={props.disableLazyload ? '' : 'lazyload'}
                        src={
                          props.disableLazyload
                            ? (props.product.image || `${process.env.NEXT_PUBLIC_ASSET_HOST}/images/default-image.jpg`)
                            : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
                        }
                        data-src={props.product.image || `${process.env.NEXT_PUBLIC_ASSET_HOST}/images/default-image.jpg`}
                        alt={props.product.title}
                        onError={handleOnImageError}
                        data-id="image"
                        showDefaultOnError
                      />

                      {
                        props.product.side_image && (
                          <Image
                            className={props.disableLazyload ? '' : 'lazyload'}
                            src={
                              props.disableLazyload
                                ? props.product.side_image
                                : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
                            }
                            data-src={props.product.side_image}
                            alt={props.product.title}
                            onError={handleOnImageError}
                            data-id="image"
                            onLoad={(e) => e.target.classList.remove('loaded')}
                            showDefaultOnError
                          />
                        )
                      }
                    </ImageWrapper>
                  )
              }

              {
                props.showQuickShop && (
                    <QuickShopButton
                      aria-label={`open ${props.product.title}, quick shop`}
                      data-id="quickshop"
                      onClick={handleOnQuickshopClick}
                      outline
                    >
                      Quick Shop
                    </QuickShopButton>
                  )
              }
            </QuickshopButtonWrapper>

            {
              props.showWishlist && (
                <Wishlist
                  aria-label={wishlistActive ? `remove ${props.product.title}, from wishlist` : `add ${props.product.title}, to wishlist`}
                  data-id="wishlist"
                  disabled={isWishlistDisabled}
                  isIcon
                  outline
                  onClick={handleOnWishlistClick}
                  active={wishlistActive ? 'active' : ''}
                >
                  <Heart className={wishlistActive ? 'active' : ''} 
                  data-id="wishlist"
                  />
                </Wishlist>
              )
            }

            <CardText product={props.product} isSearch={props.isSearch}/>
          </CardAnchor>
        </Link>
      </>
    </Card>
  );
};

ProductCard.defaultProps = {
  className: '',
  index: null,
  onClick: () => { },
  onQuickshopClick: () => { },
  showQuickShop: true,
  showWishlist: true,
  token: '',
  trackFor: '',
  disableLazyload: false,
  intersectionPosition: null,
  intersectionCallback: null,
  taxonProducts: true,
  isSearch: false
};

ProductCard.propTypes = {
  addProductToList: PropTypes.func.isRequired,
  addProductToAlgoliaList: PropTypes.func.isRequired,
  className: PropTypes.string,
  index: PropTypes.number,
  lists: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onQuickshopClick: PropTypes.func,
  product: PropTypes.object.isRequired,
  removeProductFromList: PropTypes.func.isRequired,
  removeProductFromAlgoliaList: PropTypes.func.isRequired,
  showQuickShop: PropTypes.bool,
  showWishlist: PropTypes.bool,
  token: PropTypes.string,
  trackFor: PropTypes.string,
  module: PropTypes.string.isRequired,
  disableLazyload: PropTypes.bool,
  intersectionPosition: PropTypes.number,
  intersectionCallback: PropTypes.func,
  taxonProducts: PropTypes.bool,
  updateProduct: PropTypes.func.isRequired,
  isSearch: PropTypes.bool
};

const mapStateToProps = (state) => ({
  token: state.user.spree_api_key,
  lists: state.lists
});

const mapDispatchToProps = (dispatch) => ({
  addProductToList: (product) => dispatch(addProductToList(product)),
  addProductToAlgoliaList: (product) => dispatch(addProductToAlgoliaList(product)),
  removeProductFromList: (product) => dispatch(removeProductFromList(product)),
  removeProductFromAlgoliaList: (product) => dispatch(removeProductFromAlgoliaList(product)),
  updateProduct: (id) => dispatch(updateProduct(id))
});

const ConnectedProductCard = connect(mapStateToProps, mapDispatchToProps)(ProductCard);

ProductCard.displayName = 'ProductCard';

ConnectedProductCard.whyDidYouRender = true;

export default ConnectedProductCard;
